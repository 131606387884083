<template>
    <component :is="template">
        <v-responsive width="800px" class="mx-auto member-orders py-9 px-0 px-md-3">
            <v-layout justify-center><span class="headline">포인트 선물하기</span></v-layout>

            <v-card outlined class="mt-6">
                <v-card-title class="d-block">
                    <div class="text-center"><b>{{me?.name}}</b>님</div>
                    <div class="text-center">보유 포인트<span class="primary--text"><b> {{ me?.point?.format?.() }}</b></span>원</div>
                </v-card-title>
            </v-card>
            
            <v-card elevation="0" class="mt-6">

                <v-divider />
                <v-card-text>
                    <v-card max-width="400" outlined class="mt-2 mx-auto">
                        <v-card-title class="d-flex align-start pb-0">
                            <v-text-field v-model="gift.receiver.username" label="받는 회원" prepend-inner-icon="mdi-account-circle" messages="* 받으시는분 ID를 입력 하세요" outlined dense />
                            <span class="d-inline-block d-flex align-center">
                                <v-btn color="primary" @click="exists()">회원확인</v-btn>
                            </span>
                        </v-card-title>
                        <v-card-title class="d-flex align-start pb-0">
                            <v-text-field v-model="gift.amount" label="전송 수량" prepend-inner-icon="mdi-alpha-p-circle" messages="* 선물하실 수량을 입력하세요" outlined dense />
                            <span class="d-inline-block d-flex align-center">
                                <v-btn color="primary" @click="gift.amount = me.point">전체수량</v-btn>
                            </span>
                        </v-card-title>
                        <v-card-title class="d-flex align-start pb-0">
                            <v-text-field v-model="gift.password" label="비밀번호" type="password" prepend-inner-icon="mdi-lock" messages="* 확인 비밀번호를 입력 하세요" outlined dense />
                        </v-card-title>
                    </v-card>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="summit()">
                        포인트 전송하기
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-responsive>
    </component>
</template>

<script>
import api from "@/api";
import QuantityInput from '@/components/client/mypage/quantity-input.vue';
import cryptoAes from '@/plugins/crypto-aes';
export default {
    components: { 
        QuantityInput,
    },
    data(){
        return {

            filter: {},

            pointItems: [],

            gift: {
                amount: 0,
                receiver: {
                    username: null
                },
                password: null,
            },

            me: null
        }
    },
    created(){
        console.log(this.$theme.pages);
    },
    mounted(){
        if(this.logoff) this.$router.push(`/login`);
        this.init();
    },
    methods: {
        async init(){
            await this.$store.dispatch("siteInfo");

            let { me } = await api.v1.me.profile.get();
            
            this.me = {
                name: me.name,
                phone: me.phone,
                email: me.email,
                point: me.point
            };
        },
        async exists(){
            var { isExists } = await api.v1.members.exists({ params: this.gift.receiver });
            alert(isExists ? "아이디가 확인 되었습니다" : "확인되지 않는 아이디입니다");
        },

        validate(){
            try{
                if(!this.gift.receiver.username) throw new Error("받는사람 아이디를 입력해주세요");
                if(!this.gift.amount) throw new Error("포인트를 입력해주세요");
                if(!this.gift.password) throw new Error("비밀번호를 입력해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },

        async summit(){
            if(this.validate()){
                await api.v1.me.points.gift.post({ 
                    ...this.gift,
                    password: cryptoAes.encrypt(this.gift.password)
                });

                alert("포인트 선물이 완료되었습니다");
                this.init();

            }
        }

    },
    computed: {
        template(){ return () => import(`@/templates/${this.$theme.pages.point}`) },
        
        logoff(){
            return !this.$store.state.accessToken;
        },
        siteInfo(){
            return this.$store.state.siteInfo;
        }
    }
}
</script>

<style scoped>
.member-orders .title {
    font-size: 22px !important;
    font-weight: 600;
}
.member-orders >>> .v-data-table__mobile-row {
    height: auto;
}
</style>